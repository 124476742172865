import React from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../mystyles.scss"

const Me = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="About Me" />
      <section >
        
          <div className="columns  is-centered">

            <div className="column is-one-third">
              <Bio />
            </div>

          </div>
        
      </section>
    </Layout>
  )
}

export default Me
