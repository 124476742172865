import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            linkedIn
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const social = data.site.siteMetadata?.social

  return (

      <div className="bio has-text-warning m-3" >

        <div className="block">
          <p className="is-family-monospace">
            Hi.
          </p>
        </div>

        <div className="block">
          <p className="is-family-monospace">
          I’m Becky.
          </p>
        </div>

        <div className="block">
          <p className="is-family-monospace">
          A copywriter and all-round good egg.
          </p>
        </div>

        <div className="block">
          <p className="is-family-monospace">
          I enjoy long walks on the beach and - hang on, wrong profile.
          </p>
        </div>

        <div className="block" >
          <p className="is-family-monospace" >
          I’ve got experience working with a huge range of different brands, from global corporations to local start-ups. And I’ve worked on all kinds of campaigns too - big ones, little ones, some TV, lots of print, plus radio, branding, social and more.              </p>
        </div>

        <div className="block" >
          <a href={`${social?.linkedIn || ``}`}>Connect with me on LinkedIn.</a>
        </div>

      </div>
  )
}

export default Bio
